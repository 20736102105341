import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';
// components
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Image from '@components/image/image';
import AddToBasket from '../../components/add-to-basket';
// services
import { trackProductViewed } from '../../services/tracking/tracking';
// styles
import styles from './bottle.module.scss';
import HowItWorks from '../../components/how-it-works';
import SectionSideImage from '../../components/section-side-image';
// hooks
import useGetProductPlansData from '@src/features/shop/hooks/useGetProductPlansData';
// constants
import { OUT_OF_STOCK } from '@constants/messages';

interface IProps {
  isLandingPage?: boolean;
}

const BottlePage: React.FC<IProps> = ({ isLandingPage = false }) => {
  const content = useStaticQuery(graphql`
    query Bottle {
      directusBottle {
        product {
          identifier
        }
        page_title
        page_description
        hero_title
        hero_description
        hero_image {
          title
          filename_disk
        }
        product_description
        product_description_image {
          title
          filename_disk
        }
        benefits_title
        benefits_icons {
          title
          filename_disk
        }
        benefits_image {
          title
          filename_disk
        }
        features_title
        features_list {
          title
          filename_disk
          description
        }
        section_last_image {
          title
          filename_disk
        }
        section_last_title
        section_last_description
      }
    }
  `);

  const { directusBottle } = content;
  const { dataPlans: dataProduct, loading: loadingProductPlans, isAvailable } = useGetProductPlansData(
    directusBottle.product.identifier
  );
  const location = useLocation();

  // tracking
  useEffect(() => {
    trackProductViewed({
      name: 'Bottle',
      url: location.href,
      product_id: directusBottle.product?.identifier,
      category: 'bottle',
    });
  }, []);

  return (
    <Layout showFooter={isLandingPage ? false : true}>
      <SEO title={directusBottle.page_title} description={directusBottle.page_description} />

      <section className={styles.sectionHero}>
        <div className={styles.heroContainer}>
          <div className={styles.heroRow}>
            <div className={styles.imageLeftLarge}>
              <Image
                filename={directusBottle.hero_image.filename_disk}
                alt={directusBottle.hero_image.title}
                width={600}
              />
            </div>
            <div className={styles.contentWrapperLeft}>
              <div className={styles.heroContent}>
                <h1>{directusBottle.hero_title}</h1>
                <hr className={styles.hr} />
                <p>{directusBottle.hero_description}</p>
                <AddToBasket
                  showPlanLabel={false}
                  plans={dataProduct.product_plans}
                  isAvailable={isAvailable && !loadingProductPlans}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <SectionSideImage
        content={
          <div>
            <div
              dangerouslySetInnerHTML={{
                __html: directusBottle.product_description,
              }}
            ></div>
          </div>
        }
        imagePosition="Right"
        minHeight={440}
        image={directusBottle.product_description_image.filename_disk}
      ></SectionSideImage>

      <HowItWorks
        title={directusBottle.features_title}
        items={directusBottle.features_list}
        background="PrimaryLighter"
      />

      <section className={styles.section}>
        <div className={styles.container}>
          <h2>{directusBottle.benefits_title}</h2>
          <hr className={styles.hr} />
          <div className={styles.row}>
            <div className={styles.benefitsList}>
              <ul>
                {directusBottle.benefits_icons.map((icon: any, index: number) => (
                  <li key={index} className={styles.icon}>
                    <div>
                      <Image
                        src={`${process.env.GATSBY_MEDIA_URL}${icon.filename_disk}?w=45`}
                        alt={icon.title}
                        width={45}
                      />
                    </div>
                    <span>{icon.title}</span>
                  </li>
                ))}
              </ul>
            </div>
            <div className={styles.benefitsImage}>
              <Image filename={directusBottle.benefits_image.filename_disk} alt={directusBottle.title} width={120} />
            </div>
          </div>
        </div>
      </section>

      <SectionSideImage
        background="PrimaryLighter"
        content={
          <div>
            <h2>{directusBottle.section_last_title}</h2>
            <hr className={styles.hr} />
            <p>{directusBottle.section_last_description}</p>
            {dataProduct?.product_plans ? <AddToBasket showPlanLabel={false} plans={dataProduct.product_plans} /> : null}
            {!dataProduct?.product_plans && !loadingProductPlans ? <h4>{OUT_OF_STOCK}</h4> : null}
          </div>
        }
        imagePosition="Left"
        minHeight={516}
        image={directusBottle.section_last_image.filename_disk}
      ></SectionSideImage>
    </Layout>
  );
};

export default BottlePage;
