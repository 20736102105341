import React from 'react';

import Image from '@components/image/image';

import styles from './section-side-image.module.scss';

interface IProps {
  background?: string;
  title?: string;
  content: any;
  image: string;
  imagePosition: string;
  minHeight?: number;
}

const SectionSideImage: React.FC<IProps> = ({
  background = '',
  title,
  content,
  image,
  imagePosition = 'Right',
  minHeight,
}) => {
  const ImageContainer = () => {
    return (
      <div className={styles.imageContainer}>
        <Image filename={image} width={800} />
      </div>
    );
  };
  return (
    <section className={styles['section' + background]}>
      <div className={styles.container}>
        <div className={styles.row} style={{ minHeight: minHeight }}>
          {imagePosition === 'Right' ? (
            <>
              <div className={styles.contentWrapperLeft}>{content}</div>
              <div className={styles.imageWrapperRight}>
                <ImageContainer />
              </div>
            </>
          ) : (
            <>
              <div className={styles.imageWrapperLeft}>
                <ImageContainer />
              </div>
              <div className={styles.contentWrapperRight}>{content}</div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default SectionSideImage;
